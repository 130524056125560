import Content from './Content';

function App() {

	return (
		<Content />
	);
}

export default App;
